import CustomStore from 'devextreme/data/custom_store';
import { authHeader } from '../helpers/index';
const stores = [];
const stores2 = [];
export const storeService = {

    getStore,
    getObjectByKey,
    getCustomStore


}
function getStore(controllerName) {
   /// console.log("getStore");
    if (stores[controllerName] === undefined) {
       // console.log("getStore - init");
        stores[controllerName] = new CustomStore({
            key: 'Id',
            loadMode: "raw",

            load: function (loadOptions) {

                return fetch("List?ObjectName=" + controllerName, { method: 'GET', headers: authHeader() })
                    .then(response => response.json())
                    .then((data) => {
                        return data.Records;
                        //totalCount: data.totalCount,
                        //summary: data.summary,
                        //groupCount: data.groupCount

                    })
                    .catch(() => {
                        throw 'Data Loading Error';
                    });
            },
            //byKey: function (key) {

            //    var query = "/List?ObjectName=" + controllerName + "&Key=" + key;
            //    const requestOptions = { method: 'GET', headers: authHeader() };
            //    return fetch(query, requestOptions)
            //        .then(response => response.json())
            //        .then((data) => {
            //            return data.InnerObject
            //        });
            //}
        });


    }
    return stores[controllerName];

}

function getCustomStore(controllerName) {
    //console.log("getCustomStore");
    if (stores2[controllerName] === undefined) {
      //  console.log("getCustomStore - init");
        const head0 = authHeader();
        const head1 = {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        };
        const head = { ...head0, ...head1 };
        stores2[controllerName] = new CustomStore({
            key: 'Id',


            load: function (loadOptions) {

                return fetch("List/Data?ObjectName=" + controllerName, {
                    method: 'POST', headers: head,
                    body: JSON.stringify(loadOptions)
                })
                    .then(response => response.json())
                    .then((result) => {
                        return result;
                        //totalCount: data.totalCount,
                        //summary: data.summary,
                        //groupCount: data.groupCount

                    })
                    .catch(() => {
                        throw 'Data Loading Error';
                    });
            },
            //byKey: function (key) {

            //    var query = "/List?ObjectName=" + controllerName + "&Key=" + key;
            //    const requestOptions = { method: 'GET', headers: authHeader() };
            //    return fetch(query, requestOptions)
            //        .then(response => response.json())
            //        .then((data) => {
            //            return data.InnerObject
            //        });
            //}
        });


    }
    return stores2[controllerName];

}


async function getObjectByKey(controllerName, id) {
    var query = "List?ObjectName=" + controllerName + "&Key=" + id;
    const requestOptions = { method: 'GET', headers: authHeader() };
    const response = await fetch(query, requestOptions);
    const data = await response.json();
    return data.InnerObject;

}
