import React, { useState } from 'react';
import { Ui } from "../Ui";
import { Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, Label, Input } from 'reactstrap';
import { Button, Form, Popup } from "devextreme-react";
import { Item } from "devextreme-react/form";
import { ToolbarItem } from 'devextreme-react/popup';
import { authenticationService } from '../services/authentication.service';

const LoginView = () => {
    const [data, setData] = useState({ username: "", password: "" });

    const [open, setOpen] = useState(true);

    const login = () => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(data)
        };

        fetch("/users/authenticate", requestOptions)
            .then(response =>
                response.text()
            )
            .then(user => {
                var msg = JSON.parse(user);
                if (msg.Error) {
                    Ui.showErrors(msg.Error);
                } else {
                    authenticationService.login(msg);
                }
            });


    };
    return (
        <React.Fragment>
            <Popup visible={open}
                showTitle={true} showCloseButton={false}
                title="Přihlášení"
                width="auto"
                height="auto"

            >
                <Form
                    formData={data}
                    onEditorEnterKey={login}
                    width={300}
                >
                    <Item dataField="username" label={{ text: "Uživatel" }} />
                    <Item dataField="password" label={{ text: "Heslo" }} editorOptions={{
                        mode: "password"
                    }} />
                </Form>





                <ToolbarItem
                    widget="dxButton"
                    toolbar="bottom"
                    location="after"
                    onClick={login}

                    options={{ icon: "user", text: "Přihlásit", type: "default", stylingMode: "contained" }}
                >
                </ToolbarItem>
            </Popup>

        </React.Fragment>
    );
};
export default LoginView;
