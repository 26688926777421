import React, { useState } from 'react';
import { TabContent, TabPane, Nav, NavItem, NavLink, Card, Button, CardTitle, CardText, Row, Col } from 'reactstrap';
import classnames from 'classnames';
import { authHeader } from '../helpers/index';
import CustomStore from 'devextreme/data/custom_store';
import DataGrid, {
    Column, Editing,
    Grouping,
    GroupPanel,
    Pager,
    Paging,
    Scrolling,
    SearchPanel,
    StateStoring
} from 'devextreme-react/data-grid';
import { TextBox } from "devextreme-react";

const PanelsView = ({ controllerName, currentItemId }) => {
    const [activeTab, setActiveTab] = useState('1');
    const [refresh, setRefresh] = useState(Date.now());
    const toggle = (tab) => {
        if (activeTab !== tab)
            setActiveTab(tab);
    };

    const attachmentStore = new CustomStore({
        key: 'Id',
        load: function (loadOptions) {
            if (currentItemId == '' || currentItemId == undefined)
                return null;
            return fetch("List?ObjectName=Attachment&Filter=ObjectId%3D%27" + currentItemId + "%27", {
                method: 'GET',
                headers: authHeader()
            })
                .then(response => response.json())
                .then((data) => {
                    return {
                        data: data.Records,
                        //totalCount: data.totalCount,
                        //summary: data.summary,
                        //groupCount: data.groupCount
                    };
                })
                .catch(() => {
                    throw 'Data Loading Error';
                });
        }

    });

    const historyStore = new CustomStore({
        key: 'Id',
        load: function (loadOptions) {
            if (activeTab != '1')
                return null;
            if (currentItemId == '' || currentItemId == undefined)
                return null;
            return fetch("List?ObjectName=Action&Filter=ObjectId%3D%27" + currentItemId + "%27", {
                method: 'GET',
                headers: authHeader()
            })
                .then(response => response.json())
                .then((data) => {
                    return {
                        data: data.Records,
                        //totalCount: data.totalCount,
                        //summary: data.summary,
                        //groupCount: data.groupCount
                    };
                })
                .catch(() => {
                    throw 'Data Loading Error';
                });
        }

    });

    const commentStore = new CustomStore({
        key: 'Id',
        load: function (loadOptions) {
            if (activeTab != '2')
                return null;
            if (currentItemId == '' || currentItemId == undefined)
                return null; //parentId ne objectId
            return fetch("List?ObjectName=Comment&Filter=ParentId%3D%27" + currentItemId + "%27&selectColumn=CreateDate,CreateUser.Name,Message,", {
                method: 'GET',
                headers: authHeader()
            })
                .then(response => response.json())
                .then((data) => {
                    return {
                        data: data.Records,
                        //totalCount: data.totalCount,
                        //summary: data.summary,
                        //groupCount: data.groupCount
                    };
                })
                .catch(() => {
                    throw 'Data Loading Error';
                });
        }

    });

    const saveComment = (e) => {

        if (e.event.target.value != "") {
            const value = e.event.target.value;
            e.component.reset();
            const head0 = authHeader();
            const head1 = {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            };
            const head = { ...head0, ...head1 };
            fetch("/list/add", {
                method: 'POST',
                headers: head,
                body: JSON.stringify({
                    ObjectName: "Comment",
                    Changes: [{ Property: "Message", Value: value },
                    { Property: "ObjectId", Value: document.CBIS.metadata[controllerName].Id },
                    { Property: "ParentId", Value: currentItemId }]
                })
            }).then(() => {
                setRefresh(Date.now());
                
            }
            );
        }
    };
    //console.log("render PANELS");
    return (<div className="panels">
        <Nav tabs>
            <NavItem>
                <NavLink
                    className={classnames({ active: activeTab === '1' })}
                    onClick={() => {
                        toggle('1');
                    }}
                >
                    Historie změn
                    </NavLink>
            </NavItem>
            <NavItem>
                <NavLink
                    className={classnames({ active: activeTab === '2' })}
                    onClick={() => {
                        toggle('2');
                    }}
                >
                    Komentáře
                    </NavLink>
            </NavItem>
            <NavItem>
                <NavLink
                    className={classnames({ active: activeTab === '3' })}
                    onClick={() => {
                        toggle('3');
                    }}
                >
                    Přílohy
                    </NavLink>
            </NavItem>
        </Nav>
        <TabContent activeTab={activeTab}>
            <TabPane tabId="1">
                {
                    activeTab == "1" ?
                        <DataGrid
                            dataSource={historyStore}
                            allowColumnReordering={true}
                            showBorders={true} height={"115"} width={"100%"}
                            className={"grid"}
                            selection={{mode: 'single'}}

                        >
                            <Scrolling mode="virtual"/>
                            <Column caption="Datum" width="120px" dataField="CreateDate" dataType="datetime"/>
                            <Column caption="Provedl" width="120px" dataField="CreateUserName"/>
                            <Column caption="Popis" width="150px" dataField="Description"/>
                            <Column caption="Dlouhý popis" dataField="LongDescription" width="300px"/>
                        </DataGrid>
                        : null
                }
            </TabPane>
            <TabPane tabId="2">
                {
                    activeTab=="2" ?
                    <DataGrid
                        dataSource={commentStore}
                        allowColumnReordering={true}
                        showBorders={true} height={"89"} width={"100%"}
                        className={"grid"}
                        selection={{mode: 'single'}}
                    >
                        <Scrolling mode="virtual"/>
                        <Column caption="Datum" sortOrder='desc' width="120px" dataField="CreateDate"
                                dataType="datetime"/>
                        <Column caption="Provedl" width="120px" dataField="CreateUserName"/>
                        <Column caption="Komentář" width="150px" dataField="Message"/>
                    </DataGrid>
                        : null
                }
                <TextBox placeholder="Nový komentář..." onEnterKey={saveComment} stylingMode="filled" />
            </TabPane>
            <TabPane tabId="3">
            </TabPane>
        </TabContent>
    </div>
    );


};

export default PanelsView;