import React, { useState, useEffect } from 'react';
import { Route } from 'react-router';
import Layout from './components/Layout';
import Home from './components/Home';
import LoginView from './components/LoginView';
import { authenticationService } from './services/authentication.service';
import { authHeader } from './helpers/index';
import ListForm from "./components/ListForm";
import LoadPanel from "devextreme-react/load-panel";
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import 'devextreme/dist/css/dx.light.compact.css';
import './custom.css'
import csMessages from "devextreme/localization/messages/cs.json";
import csMessages_CBIS from "./localization/cs.json";
import { formatMessage, loadMessages, locale } from 'devextreme/localization';
import packageJson from '../package.json';


const App = () => {
    const displayName = App.name;
    
    const [menuItems, setMenuItems] = useState([]);
    const [isLogged, setIsLogged] = useState(authenticationService.currentUserValue != null);
    const [started, setStarted] = useState(false);
    const [currentUser, setCurrentUser] = useState(null);

    useEffect(() => {
        
        loadMessages(csMessages);
        loadMessages(csMessages_CBIS);
        ///console.log(csMessages_CBIS);
        locale("cs");

        authenticationService.currentUser.subscribe(x => {
            setCurrentUser(x);
            setIsLogged(x != null);
            if (x != null) {
                loadAppData();

            }
        });
    }, []);

    async function loadAppData() {

    
        const requestOptions = { method: 'GET', headers: authHeader() };
        try {
            const response = await fetch("menuitem", requestOptions)
            const data = await response.json();
            setMenuItems(data);
        }
        catch(ex){
            authenticationService.logout();
            setIsLogged(false);
          ///  window.location.reload();
        }
        try {
            ///load metadata
            document.CBIS = new Array();
            const metadata = await fetch("XpMetadata", { method: 'GET', headers: authHeader() })
            document.CBIS.metadata = await metadata.json();
            setStarted(true);
        }
        catch (ex){
            authenticationService.logout();
            setIsLogged(false);
           /// window.location.reload();

        }
      
    }


    const render = () => {

    };

    return (
        <div>
            {
                isLogged ?
                    //přihlášen
                    !started ? <React.Fragment>
                        <LoadPanel
                            shadingColor="#cfddee"
                            position={{ of: window }}
                            message={formatMessage('NahravamAplikaci')}
                            visible={true}
                            showIndicator={true}
                            shading={true}
                            showPane={true}

                        />
                        <div className="version">v:{packageJson.version}</div>
                    </React.Fragment>
                        :
                        //Data načtena
                        <Layout>
                            <Route exact path='/' component={Home} />
                            {menuItems.map((item, index) => {

                                return (item.Childern.map((child, i2) => {
                                    return (
                                        <Route key={i2} path={"/" + child.ObjectName}
                                            component={() => <ListForm controllerName={child.ObjectName} />} />

                                    )

                                }))


                            })
                            }
                            <div className="version">v:{packageJson.version}</div>
                        </Layout>
                    // nepřihlášen
                    :
                    <React.Fragment>
                        <LoginView></LoginView>
                        <div className="version">v:{packageJson.version}</div>
                    </React.Fragment>

            }
        </div>

    )
};
export default App;