import React, { useState } from 'react';
import { Container } from 'reactstrap';
import { Drawer, RadioGroup, Toolbar, Button } from 'devextreme-react';
import NavMenu from './NavMenu';
import { authenticationService } from '../services/authentication.service';

const Layout = ({ children }) => {
    const displayName = Layout.name;
    const [showLogin, setShowLogin] = useState(!authenticationService.currentUserValue);
    const [opened, setOpened] = useState(true);


    return (<div>

        <div className="all">
            <Button onClick={() => setOpened(!opened)}
                icon="menu"
                className="menuButton"
                stylingMode="outlined"
                
            />
            <Drawer
                opened={opened}

                position="left"
                openedStateMode="shrink"
                component={NavMenu}
                minSize={38}


                height="100%">
                <div id="content" className="dx-theme-background-color">
                    {children}
                </div>
            </Drawer>


        </div>

    </div>
    );
};
export default Layout;
