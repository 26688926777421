import React, { Component, useEffect } from 'react';
import Toolbar, { Item } from 'devextreme-react/toolbar';
import notify from 'devextreme/ui/notify';


import { formatMessage } from 'devextreme/localization';

const refreshButtonOptions = {
    icon: 'refresh',

};


const addButtonOptions = {
    icon: 'plus',

};

const editButtonOptions = {
    icon: 'edit',

};

const deleteButtonOptions = {
    icon: 'remove',

};



const exportButtonOptions = {
    icon: 'exportxlsx'

};

const settingsButtonOptions = {
    text: () => { return formatMessage("Nastaveni") },
    onClick: () => {
        notify('Settings option has been clicked!');
    }
};

const Toolbar2 = ({ editObject, deleteObject, addObject, refreshGrid, reports, label, exportGridToExcel,printObject }) => {
    useEffect(() => {

       // console.log(formatMessage("Yes"));
        //console.log(formatMessage("Nastaveni"));

    }, []);
    const printButtonOptions = {

        icon: 'print',
        stylingMode: "contained",
        text: 'Tisk',
        displayExpr: 'Caption',
        keyExpr: 'Id',
        focusStateEnabled:false,
        onItemClick: (e) => {
            printObject(e.itemData.Id)
        },
        items: reports,

    };

    return (
        <React.Fragment>
            <div className="toolbar">
                <Toolbar>
                    <Item location="before"
                        widget="dxButton"
                        options={addButtonOptions} onClick={addObject} />
                    <Item location="before"
                        widget="dxButton"
                        options={editButtonOptions} onClick={editObject} />
                    <Item location="before"
                        widget="dxButton"
                        options={deleteButtonOptions} onClick={deleteObject} />
                    <Item location="after"
                        locateInMenu="auto"
                        widget="dxButton"
                        options={exportButtonOptions} onClick={exportGridToExcel} />

                    <Item location="before"

                        locateInMenu="auto"
                        widget="dxButton"
                        options={refreshButtonOptions} onClick={refreshGrid} />
                    <Item location="before"
                        locateInMenu="auto"
                        widget="dxDropDownButton"
                        options={printButtonOptions} />
                    <Item location="center"
                        locateInMenu="never"
                        text={label}
                    />



                    <Item locateInMenu="always"
                        widget="dxButton"
                        options={settingsButtonOptions} />
                </Toolbar>

            </div>
        </React.Fragment>
    )
};
export default Toolbar2;
