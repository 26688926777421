import React, { useState, useEffect } from 'react';
import  {Button, Drawer, Toolbar } from 'devextreme-react';
import { Collapse, Container, Navbar, NavbarBrand, NavItem, NavLink, Nav } from 'reactstrap';
import { Link } from 'react-router-dom';
import './NavMenu.css';
import { authHeader } from '../helpers/index';
import TreeView from 'devextreme-react/tree-view';
import {authenticationService} from "../services/authentication.service";

const NavMenu = ({ onCloseClick }) => {
    const displayName = NavMenu.name;
    const [menuItems, setMenuItems] = useState([""]);
    const [loading, setLoading] = useState(true);
    const [opened, setOpened] = useState(true);
    const [collapsed, setCollapsed] = useState(true);

    const toggleNavbar = () => {
        setCollapsed(!collapsed);
    };
    useEffect(() => {
        populateTableData();
    }, []);


    async function populateTableData() {
        try {
            const requestOptions = {method: 'GET', headers: authHeader()};
            const response = await fetch("menuitem", requestOptions);
            const data = await response.json();
            setMenuItems(data);
            setLoading(false);
        }
        catch{
            
        }
    }
    
    const logout = (e) =>{
        if(!loading){
            
            authenticationService.logout();
            window.location.reload();
        }
    };

    const renderTableData = (data, collapsed) => {
        return (
            <div>
              
                <Navbar className="navbar-expand-sm navbar-toggleable-sm ng-white" dark>



                    <Collapse className="" isOpen={collapsed} navbar>
                        <ul className="navbar-nav flex-grow">
                            {data.map((menuItem, index) => {
                                return (
                                    <NavItem key={index}>
                                        <NavLink key={index} tag={Link} to={"/"}>{menuItem.Caption}</NavLink>
                                        <Nav>
                                            {menuItem.Childern.map((child, i2) => {
                                                return (
                                                    <NavItem key={i2}>
                                                        <NavLink tag={Link}
                                                            to={"/" + child.ObjectName}>{child.Caption}</NavLink>
                                                    </NavItem>
                                                )

                                            })}
                                        </Nav>
                                    </NavItem>
                                )
                            }
                            )}
                    </ul>
                    </Collapse>
                    <Button
                        className="btLogout"
                        width={120}
                        text="Odhlásit se"
                        type="normal"
                        icon="user"
                        stylingMode="outlined"
                        onClick={logout}
                    />
                </Navbar>
            </div>
        );
    };

    let contents = loading
        ? <p><em>Loading...</em></p>
        : renderTableData(menuItems, collapsed);

    return (
        <div className="navigation">{contents}</div>
    );

};

export default NavMenu;