import React, { useState, useEffect, useRef, createRef } from 'react';
import Grid from "./Grid";
import Toolbar2 from "./Toolbar";
import PanelsView from "./PanelsView";
import CiselneRadyForm from "./Forms/CiselneRadyForm";
import { authHeader } from '../helpers/index';
import ZakazniciForm from "./Forms/ZakazniciForm";
import FakturaVystavenaForm from "./Forms/FakturaVystavenaForm";
import ObjednavkaVystavenaForm from "./Forms/ObjednavkaVystavenaForm";
import DodaciListVystavenyForm from "./Forms/DodaciListVystavenyForm";
import LoadPanel from "devextreme-react/load-panel";
import { formatMessage } from 'devextreme/localization';
import { confirm } from 'devextreme/ui/dialog';
import UserForm from "./Forms/UserForm";
import { authenticationService } from "../services/authentication.service";
//let tableData = [""];

const ListForm = ({ controllerName }) => {
    const [tableData, setTableData] = useState([""]);
    const [columns, setColumns] = useState([""]);
    const [loading, setLoading] = useState(true);
    const [currentItemId, setCurrentItemId] = useState("");
    const [reports, setReports] = useState([]);
    const [showForm, setShowForm] = useState(false);
    const [formType, setFormType] = useState("");

    const gridRef = createRef();

    useEffect(() => {
       /// populateTableData().then(r => );

        if (controllerName != "") {
            setColumns(document.CBIS.metadata[controllerName].Columns);
            setReports(document.CBIS.metadata[controllerName].Reports);
            setLoading(false);
        }
       
    }, []);

    const closeForm = () => {
        setShowForm(false);
    };

    const addObjClick = () => {
        setFormType("add");
        setShowForm(true);

    };

    const exportGridToExcel = () => {
        gridRef.current.dataGrid.instance.exportToExcel(false);
    };

    const setCurrentItemIdInside = (id) => {

        setCurrentItemId(id);
    };

    const addObj = (obj, objName) => {
        var array = [];
        Object.entries(obj).map(o => {
            array.push(new Object({ Property: o[0], Value: o[1] }))
        });

        if (formType == "add") {
            const head0 = authHeader();
            const head1 = {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            };
            const head = { ...head0, ...head1 };
            fetch("/List/add", {
                method: 'POST',
                headers: head,
                body: JSON.stringify({ ObjectName: objName, Changes: array })
            }).then(() => {
                setShowForm(false);
                refresh();
            }
            );
        } else {

            const head0 = authHeader();
            const head1 = {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            };
            const head = { ...head0, ...head1 };
            fetch("/list/edit", {
                method: 'Post',
                headers: head,
                body: JSON.stringify({ Id: currentItemId, ObjectName: objName, Changes: array })
            }).then(() => {

                setShowForm(false);
                refresh();
            }
            );
        }
    };

    const showPdf = (e) => {

        //const head0 = authHeader();
        //const head1 = {

        //    'Content-Type': 'application/json'
        //};
        //const head = { ...head0, ...head1 };
        //fetch("/list/print", {
        //    method: 'POST',
        //    body: JSON.stringify({ ObjectName: controllerName, ReportId: e.itemData.id, ObjectIds: [e.itemData.objectId.Id] }),
        //    headers: head
        //})
        //    .then(res => {
        //        /// filename: res.headers.get('content-disposition').split('filename=')[1],
        //        return res.blob();
        //    })
        //    .then(b => {

        //        const fileURL = URL.createObjectURL(b);
        //        //Open the URL on new Window
        const currentUser = authenticationService.currentUserValue;
        const fileURL = "/list/exportToPdf?ObjectName=" + controllerName + "&ReportId=" + e.itemData.id + "&ObjectIds=" + e.itemData.objectId.Id + "&Token=" + currentUser.Token;
        var w = window.open(fileURL);
        ///w.print();
        ///  });
    };
    const editObjClick = (e) => {
        setFormType("edit");
        setShowForm(true);
    };
    const printObjectClick = (e) => {
        showPdf({ itemData: { id: e, objectId: { Id: currentItemId } } });
    };

    const deleteObj = () => {
        if (currentItemId != "") {
            const head0 = authHeader();
            const head1 = {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            };
            const head = { ...head0, ...head1 };
            let result = confirm(formatMessage("dxDataGrid-editingConfirmDeleteMessage"), formatMessage("dxDataGrid-editingDeleteRow"));
            result.then((dialogResult) => {
                if (dialogResult) {
                    fetch("/list/delete?ObjectName=" + controllerName + "&objectId=" + currentItemId, {
                        method: 'Post',
                        headers: head,
                    }).then(() => {

                        refresh();
                    }
                    );
                }

            });

        } else {
            // Upozorním, že nebyla vybrána položka
        }
    };

    const refresh = () => {
        console.log("refresh" + controllerName);
        if (gridRef.current)
        gridRef.current.refresh();
    };
    const addMenuItems = (e, id) => {
        if (e.target == 'content') {
            // e.items can be undefined
            if (!e.items)
                e.items = [];
            var report = [];
            reports.map((data, index) => {

                report.push({
                    text: data.Caption, id: data.Id, onItemClick: showPdf,
                    objectId: id
                    //var url = window.URL.createObjectURL(blob);
                    //window.open(url);


                });
            });
        }

        // Add a custom menu item
        e.items.push({
            icon: "print",
            text: () => { return formatMessage("Tisk") },
            items: report
        });

    };

    async function populateTableData() {
        try {
            if (controllerName != "") {
                var query = "List?ObjectName=" + controllerName;
                const requestOptions = { method: 'GET', headers: authHeader() };
                const response = await fetch(query, requestOptions);
                const data = await response.json();
                setTableData(data.Records);
            }
        }
        catch{ }
    }

    const renderForm = () => {
        if (showForm == true) {
            if (controllerName == "CiselnaRada") {
                return (
                    <CiselneRadyForm addObj={addObj} type={formType} closeForm={closeForm}
                        objectId={currentItemId} controllerName={controllerName} />
                )
            }
            if (controllerName == "Firma") {
                return (
                    <ZakazniciForm addObj={addObj} type={formType} closeForm={closeForm}
                        objectId={currentItemId} controllerName={controllerName} />
                )
            }
            if (controllerName == "FakturaVystavena") {
                return (

                    <FakturaVystavenaForm addObj={addObj} type={formType} closeForm={closeForm}
                        objectId={currentItemId}
                        controllerName={controllerName} />
                )
            }
            if (controllerName == "ObjednavkaVystavena") {
                return (

                    <ObjednavkaVystavenaForm addObj={addObj} type={formType} closeForm={closeForm}
                        objectId={currentItemId}
                        controllerName={controllerName} />
                )
            }
            if (controllerName == "DodaciListVystaveny") {
                return (

                    <DodaciListVystavenyForm addObj={addObj} type={formType} closeForm={closeForm}
                        objectId={currentItemId}
                        controllerName={controllerName} />
                )
            }
            if (controllerName == "User") {
                return (

                    <UserForm addObj={addObj} type={formType} closeForm={closeForm}
                        objectId={currentItemId}
                        controllerName={controllerName} />
                )
            }
        } else {
            return null;
        }
    };
   // console.log("render LISTFORM");
    return (
        <div>
            {
                loading ? <React.Fragment>
                    <LoadPanel
                        shadingColor="rgba(0,0,0,0.4)"
                        position={{ of: ".dx-drawer-content" }}

                        visible={true}
                        showIndicator={false}
                        shading={false}
                        showPane={false}

                    />
                </React.Fragment> : <React.Fragment>
                        <div className="listForm">
                            <Toolbar2 refreshGrid={refresh}
                                deleteObject={deleteObj} addObject={addObjClick}
                                editObject={editObjClick} reports={reports} printObject={printObjectClick}
                                label={document.CBIS.metadata[controllerName].Caption}
                                exportGridToExcel={exportGridToExcel} />
                            <Grid ref={gridRef} columns={columns} tableData={tableData}
                                setCurrentItemId={setCurrentItemIdInside} onContextMenuPreparing={addMenuItems}
                                controllerName={controllerName}
                                showForm={showForm} addObj={addObj} formType={formType} closeForm={closeForm}
                                rowDblClick={editObjClick}
                                currentItemId={currentItemId} />
                            <PanelsView currentItemId={currentItemId} controllerName={controllerName} />
                            <div className="statusbar">ID: {currentItemId}</div>
                            {renderForm()}
                        </div>
                    </React.Fragment>
            }
        </div>

    )
};
export default ListForm;