import React, {useState, useEffect} from 'react';
import {Button, Form, Popup} from "devextreme-react";
import {ButtonItem, Item} from "devextreme-react/form";
import {authHeader} from '../../helpers/index';
import {Scrolling} from "devextreme-react/data-grid";
import {ToolbarItem} from "devextreme-react/popup";
import {ScrollView} from "devextreme-react/scroll-view";
import CustomStore from "devextreme/data/custom_store";
import { storeService } from "../../services/stores.service";

let obj = {};
let newObj = {};


const UserForm = ({type, controllerName, objectId, closeForm, addObj}) => {
    const [open, setOpen] = useState(false);

    useEffect(() => {
        if (type == "add") {
            setOpen(true);
        } else {
            populateFormData();
        }
    }, []);

    async function populateFormData() {


        storeService.getObjectByKey(controllerName, objectId)
            .then(data => {
                obj = data;
                setOpen(true);

            });
    }

    const handleClose = () => {
        setOpen(false);
        setTimeout(() => {
            closeForm();
            obj = {};
            newObj = {};
        }, 500);
    };

    const handleSave = (event) => {
        addObj(newObj, "User");
        handleClose();
    };

    const handleChange = (e) => {

        if (open == false)
            return;
        //this.setState(state => ({ obj: Object.assign({}, state.obj, { [property]: val }) }), ()=>{console.log(this.state.obj)});
        if (e.dataField in newObj) {
            newObj = Object.assign({}, newObj, {[e.dataField]: e.value});
        } else {
            newObj[e.dataField] = e.value;
        }
    };
    const buttonOptions = {
        text: 'Uložit',
        useSubmitBehavior: false
    };
    return (

        <React.Fragment>
            <Popup visible={open}
                   showTitle={true} onHiding={handleClose}
                   title={document.CBIS.metadata[controllerName].Caption}
                   showCloseButton={true}
            >
                <ScrollView height="100%" width="100%">
                    <Form
                        colCount={2}
                        id="form"
                        formData={obj}
                        onFieldDataChanged={handleChange}
                    >
                        <Item dataField="FirstName" label={{text: "Jméno"}}/>
                        <Item dataField="LastName" label={{text: "Příjmení"}}/>
                        <Item dataField="Email" label={{text: "E-mail"}}/>
                    </Form>
                </ScrollView>
                <ToolbarItem
                    widget="dxButton"
                    toolbar="bottom"
                    location="after"
                    onClick={handleSave}
                    options={{icon: "save", text: "Uložit"}}
                >
                </ToolbarItem>
            </Popup>
        </React.Fragment>
    )
};
export default UserForm;
