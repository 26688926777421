import React, {useState, useEffect} from 'react';
import {authHeader} from '../../helpers/index';
import {Lookup, Form, Popup} from "devextreme-react"
import {Item, ButtonItem, GroupItem} from "devextreme-react/form"
import CustomStore from 'devextreme/data/custom_store';
import {storeService} from "../../services/stores.service";
import DataGrid, {
    Column,
    Editing,
    Paging,
    Position,
    Scrolling,
    StateStoring,
    Form as Form2,
    Popup as Popup2
} from "devextreme-react/data-grid";
import {ToolbarItem} from 'devextreme-react/popup';
import {ScrollView} from "devextreme-react/scroll-view";

let obj = {};
let newObj = {};


const DodaciListVystavenyForm = ({controllerName, objectId, closeForm, addObj, type}) => {
        const [open, setOpen] = useState(false);
        const [forceRender, setForceRender] = useState(Date.now);

        useEffect(() => {
            if (type == "add") {
                objectId = '00000000-0000-0000-0000-000000000000';
                populateFormData();
            } else {
                populateFormData();
            }
        }, []);

        async function populateFormData() {

            storeService.getObjectByKey(controllerName, objectId)
                .then(data => {
                    obj = data;
                    setOpen(true);

                });
        }

        const handleClose = () => {
            setOpen(false);
            setTimeout(() => {
                closeForm();
                obj = {};
                newObj = {};
            }, 500);
        };

        const handleSave = (event) => {
            newObj["Polozky"] = JSON.stringify(obj.Polozky);
            addObj(newObj, "DodaciListVystaveny");
            handleClose();
        };

        const handleChange = (e) => {
            if (open == false)
                return;
            let data = e.dataField;
            if (data == "Odberatel.Id") {
                newObj["Mesto"] = obj.Mesto;
                newObj["Ulice"] = obj.Ulice;
                newObj["PSC"] = obj.PSC;
                newObj["Nazev"] = obj.Nazev;
                newObj["ICO"] = obj.ICO;
                newObj["DIC"] = obj.DIC;
            }
            if (data in newObj) {
                newObj = Object.assign({}, newObj, {[data]: e.value});
            } else {
                newObj[data] = e.value;
            }
        };

        const groupedItems = {
            Popis: [{
                itemType: 'tabbed',
                tabPanelOptions: {
                    deferRendering: false
                },
                tabs: [{
                    title: 'Popis',
                    items: [
                        {label: {text: "Číslo dokladu"}, dataField: "CisloDokladu"},
                        {label: {text: "Číslo dokladu"}, dataField: "ObjednavkaCislo"},
                        {
                            label: {text: "Popis dokladu"},
                            dataField: "PopisDokladu",
                            editorType: "dxTextArea",
                            editorOptions: {height: 85}
                        },]
                }]
            }],
            Zakaznik: [{
                itemType: 'tabbed',
                tabPanelOptions: {
                    deferRendering: false
                },
                tabs: [
                    {
                        title: 'Odběratel',
                        items: [
                            {
                                dataField: "Odberatel.Id", label: {text: "Odběratel"}, editorType: "dxLookup",
                                editorOptions: {
                                    dataSource: storeService.getStore("Firma"),
                                    displayExpr: "Nazev",
                                    valueExpr: "Id",
                                    onValueChanged: (e) => {
                                        if (e.value != e.previousValue)
                                            setForceRender(Date.now());
                                    },
                                    onSelectionChanged: (e) => {
                                        obj.Nazev = e.selectedItem.Nazev;
                                        obj.Ulice = e.selectedItem.Ulice;
                                        obj.Mesto = e.selectedItem.Mesto;
                                        obj.PSC = e.selectedItem.PSC;
                                        obj.ICO = e.selectedItem.ICO;
                                        obj.DIC = e.selectedItem.DIC;
                                    }
                                }
                            },
                            {label: {text: "Název"}, dataField: "Nazev", disabled: true},
                            {label: {text: "Ulice"}, dataField: "Ulice", disabled: true},
                            {label: {text: "Město"}, dataField: "Mesto", disabled: true},
                            {label: {text: "PSC"}, dataField: "PSC", disabled: true}
                        ]

                    },
                    {
                        title: 'Konečný příjemce',
                        items: [{label: {text: "Dodací název"}, dataField: "DodaciNazev"},
                            {label: {text: "Dodací město"}, dataField: "DodaciMesto"},
                            {label: {text: "Dodací ulice"}, dataField: "DodaciUlice"},
                            {label: {text: "Dodací PSČ"}, dataField: "DodaciPSC"}]
                    }]
            }
            ]
        };


        return (
            <React.Fragment>
                <div>
                    {
                        <Popup visible={open}
                               showTitle={true} onHiding={handleClose}
                               title={document.CBIS.metadata[controllerName].Caption}
                               showCloseButton={true}

                        >
                            <ScrollView height="100%" width="100%">
                                <Form
                                    colCount={3}
                                    id="form"
                                    formData={obj}
                                    onFieldDataChanged={handleChange}
                                >
                                    <Item itemType="group" items={groupedItems.Zakaznik}/>
                                    <Item itemType="group" items={groupedItems.Popis}/>
                                    <GroupItem caption="Datum">
                                        <Item dataField="DatumVystaveni" editorType="dxDateBox"
                                              label={{text: "Datum vystavení"}}/>
                                        <Item dataField="ICO" label={{text: "IČO"}}/>
                                        <Item dataField="DIC" label={{text: "DIČ"}}/>
                                    </GroupItem>
                                </Form>
                                <div>
                                    <h6>Položky</h6>
                                    <DataGrid dataSource={obj.Polozky} onInitNewRow={(e) => {
                                        e.data.DPH = 21;
                                        e.data.Mnozstvi = 1
                                    }}
                                              showBorders={true}
                                              keyExpr="Id"
                                              height="145"
                                              selection={{mode: 'single'}}
                                              className={"grid"}>
                                        <Paging enabled={false}/>
                                        <Editing
                                            mode="popup"
                                            allowUpdating={true}
                                            allowAdding={true}
                                            allowDeleting={true}
                                        >
                                            <Popup2
                                                title="Položka"
                                                showTitle={true}
                                                width={700}
                                                height={525}
                                            >
                                                <Position my="top" at="top" of={window}/>
                                            </Popup2>
                                            <Form2>
                                                <Item dataField="Nazev" label={{text: "Popis"}}/>
                                                <Item dataField="Mnozstvi" label={{text: "Množství"}}/>
                                                <Item dataField="Cena" label={{text: "Jednotková cena"}}/>
                                                <Item dataField="MJ" label={{text: "MJ"}}/>
                                                <Item dataField="DPH" label={{text: "DPH"}} editorType="dxSelectBox" va
                                                      editorOptions={{dataSource: [0, 10, 15, 21]}}/>
                                                <Item dataField="Popis" editorType="dxTextArea"
                                                      label={{text: "Poznámka"}}
                                                      editorOptions={{height: 135}}/>
                                            </Form2>
                                        </Editing>
                                        <Column
                                            dataField="Nazev" caption="Popis"/>
                                        <Column
                                            dataField="Mnozstvi" caption="Množství"/>
                                        <Column
                                            dataField="Cena" caption="Cena"/>
                                        <Column
                                            dataField="DPH" caption="DPH"/>
                                        <Column
                                            dataField="MJ" caption="MJ"/>
                                        <Column
                                            dataField="Popis" caption="Poznámka"/>
                                    </DataGrid>
                                </div>
                            </ScrollView>
                            <ToolbarItem
                                widget="dxButton"
                                toolbar="bottom"
                                location="after"
                                onClick={handleSave}

                                options={{ icon: "save", text: "Uložit" }}
                            >
                            </ToolbarItem>
                        </Popup>
                    }
                </div>
            </React.Fragment>
        )
    }
;

export default DodaciListVystavenyForm;