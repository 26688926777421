import React, { Component, PureComponent, useEffect, useState } from 'react';
import { storeService } from '../services/stores.service';
import DataGrid, {
    Column,
    Grouping,
    GroupPanel,
    Pager,
    Paging,
    Scrolling,
    SearchPanel,
    StateStoring,
    Export
} from 'devextreme-react/data-grid';

class Grid extends Component {
    constructor(props) {
        super(props);

        this.setCurrentItemId = props.setCurrentItemId;
        this.columns = props.columns;
        this.onContextMenuPreparing = props.onContextMenuPreparing;
        this.rowDblClick = props.rowDblClick;
        this.state = { cols: [] };
        this.skipRender = false;
        this.dataGrid = null;
        ///this.onSelectionChanged = this.onSelectionChanged.bind();
    }

    componentDidMount() {
        var c = [];
        this.columns.map((data, index) => {
            if (data.Visible == true) {
                let type = "string";
                if (data.Format == "d")
                    type = "date";
                if (data.Format == "g")
                    type = "datetime";

                c.push({
                    caption: data.Caption,
                    width: data.Width,
                    dataField: data.Property,

                    dataType: type

                });
            }
        });

        this.setState({ cols: c });

    };




    handleContextMenuPreparing = (event) => {
        //if (event.row.rowType == 'data') {
        //    this.dataGrid.instance.selectRowsByIndexes(event.rowIndex);
        //    this.onContextMenuPreparing(event, this.dataGrid.instance.getKeyByRowIndex(event.rowIndex));
        //}
    };
    handleRowDblClick = (event) => {

        this.rowDblClick();
    };
    onSelectionChanged = (data) => {

        if (data != undefined && data.selectedRowsData != undefined && data.selectedRowsData[0] != undefined) {
            this.skipRender = true;
            this.setCurrentItemId(data.selectedRowsData[0]["Id"]);
        }

    };
    shouldComponentUpdate(nextProps, nextState) {
        //if (this.props.tableData !== nextProps.tableData) {
        //    return true;
        //}
        if (this.skipRender) {
            this.skipRender = false;
            return false;
        }
        return true;
    }

    refresh = () => {
        this.dataGrid.instance.refresh();
    }

    render() {
        const store = storeService.getCustomStore(this.props.controllerName);
        return (
            <DataGrid ref={(ref) => { this.dataGrid = ref; }}
                columns={this.state.cols}
                dataSource={store}
                allowColumnResizing={true}
                filterRow={{ visible: true }}
                columnResizingMode="widget"
                allowColumnReordering={true}
                className={"grid"}
                selection={{ mode: 'single' }}
                showBorders={true}
                onSelectionChanged={this.onSelectionChanged}
                onRowDblClick={this.handleRowDblClick}
                remoteOperations={true}
                onContextMenuPreparing={this.handleContextMenuPreparing} >
                <Scrolling mode="virtual" />
            </DataGrid >
        )
    }
}

export default Grid;