import React, {useState, useEffect} from 'react';
import {authHeader} from '../../helpers/index';
import {Button, Form, Popup} from "devextreme-react";
import {ButtonItem, Item} from "devextreme-react/form";
import {ScrollView} from "devextreme-react/scroll-view";
import {ToolbarItem} from "devextreme-react/popup";
import { storeService } from "../../services/stores.service";

let obj = {};
let newObj = {};

const CiselneRadyForm = ({type, controllerName, objectId, closeForm, addObj}) => {
    const [open, setOpen] = useState(false);

    useEffect(() => {
        if (type == "add") {
            setOpen(true);
        } else {
            populateFormData();
        }
    }, []);


    async function populateFormData() {

        storeService.getObjectByKey(controllerName, objectId)
            .then(data => {
                obj = data;
                setOpen(true);

            });
    }

    const handleClose = () => {
        setOpen(false);
        setTimeout(() => {
            closeForm();
            obj = {};
            newObj = {};
        }, 500);
    };

    const handleSave = (event) => {
        addObj(newObj, "CiselnaRada");
        handleClose();
    };

    const handleChange = (e) => {

        if (open == false)
            return;

        //this.setState(state => ({ obj: Object.assign({}, state.obj, { [property]: val }) }), ()=>{console.log(this.state.obj)});
        if (e.dataField in newObj) {
            newObj = Object.assign({}, newObj, {[e.dataField]: e.value});
        } else {
            newObj[e.dataField] = e.value;
        }
    };
    const buttonOptions = {
        text: 'Uložit',
        useSubmitBehavior: false
    };
    return (


        <React.Fragment>
            <div>
                {

                    <Popup visible={open}
                           showTitle={true} onHiding={handleClose}
                           title={document.CBIS.metadata[controllerName].Caption}
                           showCloseButton={true}
                    >
                        <ScrollView height="100%" width="100%">
                            <form onSubmit={handleSave} action="test">
                                <Form
                                    colCount={2}
                                    id="form"
                                    formData={obj}
                                    onFieldDataChanged={handleChange}
                                >
                                    <Item dataField="Nazev" label={{text: "Název"}}/>
                                    <Item dataField="Object" label={{text: "Objekt"}}/>
                                    <Item dataField="Prefix" label={{text: "Prefix"}}/>
                                    <Item dataField="Cislo" label={{text: "Číslo"}}/>
                                    <Item dataField="Delka" label={{text: "Délka"}}/>
                                    <Item dataField="ShowZero" label={{text: "Zobrazit nuly"}} editorType="dxCheckBox"/>
                                    <Item dataField="IsDefault" label={{text: "Výchozí"}} editorType="dxCheckBox"/>
                                </Form>
                            </form>
                        </ScrollView>
                        <ToolbarItem
                            widget="dxButton"
                            toolbar="bottom"
                            location="after"
                            onClick={handleSave}

                            options={{icon: "save", text: "Uložit"}}
                        />

                    </Popup>


                }
            </div>

        </React.Fragment>
    )
};
export default CiselneRadyForm;

