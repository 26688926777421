import notify from 'devextreme/ui/notify';

export class Ui {

    static showErrors(...messages) {

        messages.forEach(x => {
            if (!Array.isArray(x)) {
                notify(x,"error")
            }
            else {
                x.forEach(y => notify(y, "error"));
            }
        });
    }

    static showInfo(message) {
        notify(message, "info")
    }
}